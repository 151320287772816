@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";

#map {
  height: 400px;
  background: gray;
}

.input-group.has-feedback {
  span {
    position: absolute;
    margin-left: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    color: #6bc678;
    z-index: 5;
  }

  input#text-search:focus + span {
    color: white;
  }
}

input#text-search {
  font-size: 20px;
  /* padding: 7px 15px; */
  /* line-height: 67px; */
  height: 52px;
  background-color: transparentize(#6bc678, 0.9);
  border: 1px solid #6bc678;
  border-radius: 5px;
  color: #6bc678;
  padding-left: 35px;

  &::placeholder {
    color: transparentize(#6bc678, 0.3)
  }

  &:focus {
    color: white;
    box-shadow: 0 0 0 0.2rem transparentize(#6bc678, 0.7);
    background-color: #6bc678;

    &::placeholder {
      color: transparentize(white, 0.5)
    }
  }
}

body[data-display-mode=portraits] #calendar {
  display: none;
}

#display-mode-buttons {
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  height: 53px;

  .btn-large {
    display: flex;
    justify-content: center;
  }

  [data-display-value=events], [data-display-value=veranstalter] {
    &.btn-primary {
      background-color: white;
      color: #6bc678;
      border: none;
      //border: 1px solid #6bc678;
    }

    &.btn-secondary {
      background-color: white;
      color: transparentize(#6bc678, 0.7);
      border: none;
      //border: 1px solid #6bc678;
    }
  }

  [data-display-value=packed], [data-display-value=list] {
    &.btn-primary {
      background-color: white;
      color: $blue;
      border: none;
      //border: 1px solid $blue;
    }

    &.btn-secondary {
      background-color: white;
      color: transparentize($blue, 0.7);
      border: none;
      //border: 1px solid $blue;
    }
  }
}

#event-list {


  .spinner {
    margin: 20px auto 0;
    display: block;
  }

  &[data-layout-mode=vertical] {
    .grid-item {
      width: 100%;
      margin-bottom: 16px;

      .card {
        //max-height: 120px;
        .card-body {
          //flex: 1 1 100%;
          //min-height: 1px;
          //padding: 1.25rem;
        }

        .card-img-custom {
          position: relative;
          max-height: 100%;
          flex: 0 0 1px;

          top: 0;
          bottom: 0;

          img {
            object-fit: cover;
            height: auto;
            position: relative;
            max-height: 100%;
            width: 180px;

          }

        }

        flex-direction: row;
        justify-items: stretch;
      }
    }
  }

  &[data-layout-mode=packery] {
    .card-img-custom {
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px);
      flex-shrink: 0;
      width: 100%;
    }

    img {
      max-width: 100%;

      &:before {
        content: "";
        padding-top: 56.4189189%;
        width: 1px;
        margin-left: -1px;
        float: left;
        height: 0;
      }

      &:after {
        content: "";
        display: table;
        clear: both;
      }
    }

    margin: 0 -8px;

    .grid-item {
      width: 50%;
      margin-bottom: 16px;
      @include media-breakpoint-up(lg) {
        width: calc((100% / 3));
        padding: 0 8px;
      }
    }

  .event-teaser {
    min-height: 280px;
  }
    //float: left;
    //width: 50px;
    //height: 50px;
    //background: #e6e5e4;
    //border: 2px solid #b6b5b4;
  }

  .event-teaser {
    margin: 5px;
    color: $blue;
    border: none;


    .card-body {
      padding: 7px;
      display: flex;
      flex-direction: column;

      > div {
        padding-left: 8px;
      }
    }

    .card-title {
      font-size: 17px;
      line-height: 22px;
      flex: 1;
      padding-left: 8px;
    }

    .event-teaser-date {
      font-size: 12px;
      font-weight: 700;
      padding: 0 8px 6px 8px;

    }
  }

  .highlight .event-teaser {
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.34);
  }
}

#calendar, #categories, #cities {
  margin-bottom: 2rem;
}

li.event-selector {
  cursor: pointer;

  display: block;
  padding-bottom: 2px;
  //list-style: none;
  &:before {
    @extend %fa-icon;
    @extend .far;
    //font-family: $fa-font-display;
    content: fa-content($fa-var-square);
    margin: 0 5px 0 5px;
    //color: #f00;
  }

  &.active {
    &:before {
      content: fa-content($fa-var-check-square);
    }

    //font-weight: bold;
  }
}

.dp-note {
  position: absolute;
  background: #ccc;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  left: 50%;
  bottom: 1px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.toolbox-headline {
  h1, h2, h3 {
    font-family: $font-family-header;
    color: $blue;
  }
}

.loader-ellips {
  font-size: 20px; /* change size here */
  position: relative;
  width: 4em;
  height: 1em;
  margin: 10px auto;
}

.loader-ellips__dot {
  display: block;
  width: 1em;
  height: 1em;
  border-radius: 0.5em;
  background: #555; /* change color here */
  position: absolute;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

.loader-ellips__dot:nth-child(1),
.loader-ellips__dot:nth-child(2) {
  left: 0;
}

.loader-ellips__dot:nth-child(3) {
  left: 1.5em;
}

.loader-ellips__dot:nth-child(4) {
  left: 3em;
}

@keyframes reveal {
  from {
    transform: scale(0.001);
  }
  to {
    transform: scale(1);
  }
}

@keyframes slide {
  to {
    transform: translateX(1.5em)
  }
}

.loader-ellips__dot:nth-child(1) {
  animation-name: reveal;
}

.loader-ellips__dot:nth-child(2),
.loader-ellips__dot:nth-child(3) {
  animation-name: slide;
}

.loader-ellips__dot:nth-child(4) {
  animation-name: reveal;
  animation-direction: reverse;
}
