table.table {
    thead {
        background-color: rgb(107, 198, 120);
        border-color: rgb(107, 198, 120);
        color: #fff;
    }

    .hidden td {
        text-decoration: line-through;
    }
}
