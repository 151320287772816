// Bootstrap4 functions
@import "./fonts/fonts";
@import "~bootstrap/scss/functions";

// Bootstrap4 variables
@import "~bootstrap/scss/variables";
// Custom Bootstrap4 variables
@import "./variables";

// Bootstrap4 components
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/print";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
//@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
//@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
//@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
//@import "~bootstrap/scss/progress";
//@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
//@import "~bootstrap/scss/popover";
//@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";

// Node Modules
@import "~lightgallery/src/sass/lightgallery.scss";
@import "~slick-carousel/slick/slick";
@import "~sidr/src/scss/jquery.sidr.bare";
@import "~select2/dist/css/select2.min.css";
@import "~@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css";
@import "~fine-uploader/fine-uploader/fine-uploader-gallery.css";
@import "~image-picker/image-picker/image-picker.css";
@import "~air-datepicker/dist/css/datepicker.css";
//@import "~gap-watcher/dist/scss/gaps";

// Mixins
//@import "./mixins/mixins";

// Bootstrap extensions, overrides
//@import "./layout/bootstrap-extensions";

// Styletile
//@import "./styletile";

// Bootstrap extensions, overrides
//@import "./layout/bootstrap-extensions";

// Styletile
//@import "./styletile";

// Fonts

@import "./elements/socialmedia";
@import "./elements/spinner";
@import "./elements/sidebar";
@import "./elements/image_gallery";
@import "./elements/image_uploader";

@import "~@fortawesome/fontawesome-free/scss/fontawesome";
//@import "./fonts/iconfont";

// Layout
//@import "./layout/animations";
//@import "./layout/layout";
//@import "./layout/typography";
//@import "./layout/header";
//@import "./layout/footer";
//@import "./layout/sidebar";
//
// Elements
//@import "./elements/form";
//@import "./elements/lightgallery";
//@import "./elements/file-uploader";
//@import "./elements/slider";
//@import "./elements/mobile-nav";
@import "elements/create_update_event";
@import "elements/card";
@import "elements/table";
@import "frontend/event_list";
@import "frontend/event_detail";
@import "frontend/header";
@import "frontend/buttons";
@import "frontend/outdated";

@import "~outdatedbrowser/outdatedbrowser/outdatedbrowser";

#outdated {
    //width: 100%;
    height: 250px;
    //height: 100vh;
    //padding-top: 15%;
}


// Toolbox
//@import "./modules/toolbox/accordion";
//@import "./modules/toolbox/anchor";
//@import "./modules/toolbox/columns";
//@import "./modules/toolbox/container";
//@import "./modules/toolbox/content";
//@import "./modules/toolbox/downloads";
//@import "./modules/toolbox/gallery";
//@import "./modules/toolbox/google-map";
//@import "./modules/toolbox/headline";
//@import "./modules/toolbox/image";
//@import "./modules/toolbox/linklist";
//@import "./modules/toolbox/parallax-container";
//@import "./modules/toolbox/slide-columns";
//@import "./modules/toolbox/spacer";
//@import "./modules/toolbox/teaser";
//@import "./modules/toolbox/video";

// Modules (custom areas)
//.eg @import "./modules/formbuilder/layout
//@import "./modules/news";
