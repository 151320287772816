input.img_upload {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    & + label {
        font-size: 1.25em;
        font-weight: 700;
        color: white;
        background-color: black;
        cursor: pointer;
        display: flex;
        width: 100%;
    }

    &:focus + label,
    & + label:hover {
        background-color: red;
    }

    &:focus + label {
        outline: 1px dotted #000;
    }

    & + label * {
        pointer-events: none;
    }
}

#gallery {
    $padding: 0.5rem;
    $rows: 3;
    display: flex;
    flex-wrap: wrap;
    margin: 0 #{-$padding};

    > div {
        flex: 1 1 calc(#{1/$rows*100%} - #{$rows*$padding});
        margin: $padding;

        img {
            max-width: 100%;
        }

        input {
            margin-right: 2px;
        }

        svg {
            cursor: pointer;
        }
    }
}

.qq-uploader-selector.qq-uploader.qq-gallery.qq-no-history {
    min-height: 0;

    &:before {
        top: initial;
    }
}
