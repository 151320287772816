/////////////////////
////////////////////

// Font Path
$font-path: "../fonts";
// Font Awesome
$fa-font-path: $font-path;
// Light Gallery
$lg-path-fonts: '/static/fonts/lightgallery';
$lg-path-images: '/static/images/lightgallery';
$lg-toolbar-bg: $black;

//
$custom-file-text: (
    en: "Browse",
    de: "Datei",
    de_CH: "Datei"
);

//// Colors
$card-img-overlay-padding: 0;
$card-bg: rgb(241, 245, 248);
$green: rgb(107, 198, 120);
$blue: rgb(5, 56, 98);
///////////////////////
// Bootstrap variables
//////////////////////

$font-family-base: "Cabin", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-header: Rufina, Arial, serif, $font-family-base;
// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               .7rem;
$btn-padding-x:               30px;
$btn-font-size:               18px;
$btn-line-height:             $input-btn-line-height;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           50px;
$btn-border-radius-lg:        60px;
$btn-border-radius-sm:        40px;

$btn-transition:              color .3s ease-in-out, background-color .3s ease-in-out, border-color .3s ease-in-out, box-shadow .3s ease-in-out;


$link-color: $blue;
$link-decoration: none !default;
$link-hover-color: lighten($link-color, 15%) !default;
$link-hover-decoration: underline !default;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
        xs: 0,
        ty: 480px,
        sm: 768px,
        md: 992px,
    lg: 1170px,
        xl: 1600px
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        sm: 768px,
    md: 900px,
    lg: 1170px,
    xl: 1170px
);

// Forms
$input-color:                           $black;

$input-border-radius:                   2px;
$input-border-radius-lg:                $input-border-radius;
$input-border-radius-sm:                $input-border-radius;

$input-focus-bg:                        $white;
$input-focus-border-color:              lighten($input-color, 25%);
$input-focus-color:                     $input-color;

