.portrait-events-content {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    @include media-breakpoint-up(md) {
        max-height: 320px;
        overflow-y: auto;
        display: block;
    }


    .small-event {
        flex: 1 0 50%;
        padding: 10px;
        position: relative;
        display: flex;

        a.stretched-link1 {
            //position: relative;
            //display: block;
            //background-color: $blue !important;
            //display: block;
            //margin: 0 auto;
            //width: 200px;
            //border-radius: 5px;
            //transition: background-color 0.5s;
            //background-color: lightblue;
            //text-align: center;
            //font-weight: 600;
            //font-size: 25px;
        }

        //flex-direction: row;
        max-width: 100%;
        min-height: 90px;
        margin-bottom: 10px;
        @include media-breakpoint-up(sm) {
            border-bottom: 1px solid $blue;
            padding: 0 0 10px;
        }

        .image {
            background-color: $green;
            flex: 0 0 40%;
            height: 100%;
            //margin-right: 5px;
            overflow: hidden;

            img, picture {
                width: 100%;
                height: 100%;

            }
        }


        .content {
            $size: 16px;
            font-size: $size;
            padding-left: 14px;
            //background-color: $blue;
            //color: white;
            flex: 1 0 60%;
            height: 100%;

            .date {
                font-weight: 700;
                //overflow: hidden;
                //text-overflow: ellipsis;
                //display: -webkit-box;
                //-webkit-box-orient: vertical;
                //-webkit-line-clamp: 2; /* number of lines to show */
                //line-height: $size; /* fallback */
                //max-height: $size*2; /* fallback */
                //min-height: $size*2; /* fallback */
            }

            .title {
                font-size: 1em;
            }

            .city {
                font-size: 0.8em;
            }

        }
    }


}

.event-detail {
    .event-description {
        margin-bottom: 2rem;
    }

    .event-info {
        margin-bottom: 2rem;
    }


}

.event-gallery {
    margin-bottom: 2rem;

    > div {
        margin-bottom: 1.875rem;
    }
}

.google-maps {
    position: relative;
    padding-bottom: 75%;
    height: 0;
    overflow: hidden;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }
}

.portrait-logo {
    margin: 3rem auto;
}

.event-meta.small {
    .canceled & {
        text-decoration: line-through;
    }

    color: $blue;
    font-size: 17px;
    font-weight: 700;
    line-height: 14px;
    margin: 0;
    padding-bottom: 3px;
    //text-transform: uppercase;
}

.event-title.title {
    margin-top: 0.5rem;

    h1, h2, h3 {
        font-family: $font-family-header;
        color: $blue;
    }
}


.social {
    position: absolute;
    top: 0;
    right: 0;
}

.portrait-events-container {
    margin-bottom: 2rem;
}
