header {
    overflow: hidden;

    nav.navbar {
        position: fixed;
        //height: 100%;
        //width: 100%;
        padding-top: 20px;
        z-index: 10;
        left: 0;
        right: 0;
        top: 0;
        background-color: rgba(255, 255, 255, 0.95);

        .navbar-brand {
            margin: 0;
        }

        .container {
            align-items: flex-end;
        }

        img {
            max-width: 100%;
            height: auto;
        }
    }

    div.navbar-wrapper {
        height: 120px;
    }
}

header .image img {
    //margin-left: 50%;
    //transform: translateX(-50%);
    max-width: 100%;
}

#main-content {
    padding-top: 4rem;
}
