.event-time-entry {
    display: flex;
    //flex-direction: column;
    justify-content: space-between;
    flex-flow: wrap;

    .form-group {
        flex: 0 0 100%;
    }
}

.preview-logo {
    max-width: 100%;
}

.date-container {
    @include media-breakpoint-down(md) {
        flex: 1 1 50%;
    }
}

.date-time-element {
    align-items: initial;
    flex-flow: nowrap;
    @include media-breakpoint-down(md) {
        display: flex;
        flex: 1 0 100%;
        padding-top: 0.5rem;

    }

    @include media-breakpoint-down(ty) {
        //align-items: center;
    }

}

.remove-collection-widget {
    position: absolute;
    right: -8px;
    font-size: 20px;
    top: 0;
    transform: translateY(-50%);
    cursor: pointer;
    color: $danger;

}

.deletion-notice {
    font-size: 1rem;
    font-style: italic;;
    color: $gray-700;
}

.social-media-entry {
    display: flex;
    justify-content: flex-start;

    div:last-of-type {
        margin-left: 1rem;
        flex: 1;
    }
}

.add-another-collection-widget {
    i, svg {
        font-size: 1.5rem;
        cursor: pointer;
        display: inline-flex;
        align-self: center;
        color: $primary;
    }
}

svg.svg-inline--fa {
    display: inline-flex;
    align-self: center;
}
