.btn {

    width: 100%;
    padding: 2px 10px;
    text-align: left;
    border-radius: 10px;
    font-size: 1rem;

    &.btn-kv, &.btn-navigate {
        width: auto;
    }

    &.btn-green {
        background-color: rgb(107, 198, 120);
        border-color: rgb(107, 198, 120);
        color: #fff;
        //text-align: center;
    }

    &.btn-blue {
        background-color: rgb(5, 56, 98);
        border-color: rgb(5, 56, 98);
        color: #fff;
    }

    &.btn-gray {
        background-color: #7A7A7A;
        border-color: #7A7A7A;
        color: #f3f3f3 !important;
    }

    &.btn-large {
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        display: block;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        margin: 0;
        padding: 10px 15px;
        position: relative;
    }
}

label.required, legend.required {
    &:after {
        content: '*';
        color: red;
    }
}
