.sidebar {
    > div {
        margin-bottom: 0.5rem;
    }

    .event-info table {
        font-size: 0.7rem;
        font-weight: lighter;
    }

    .card {
        margin-bottom: 1.875rem;
    }
}

.notifications {
    max-width: 400px;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
}
